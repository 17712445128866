<div
  class="card plan-card"
  [ngClass]="{ 'plan-card-detailed': detailedMode() }"
>
  <div
    #planCard
    id="planCard"
    class="card-body row d-flex flex-column"
    *ngIf="plan"
  >
    <div class="col-12 d-flex">
      <div
        class="col-8"
        [ngClass]="{
          'mb-2 mb-md-3': !detailedMode(),
          'align-content-between': detailedMode()
        }"
      >
        <div class="card-title">Meu Plano</div>
        <div class="plan-info py-1 col-12 col-md-12 d-flex align-items-center">
          <b class="text-nowrap me-2">Nome do Plano: </b>
          @if(hasPlansecondary){
          <select
            class="form-select w-50"
            [formControl]="selectedPlan"
            (change)="setPlanSelected($event)"
          >
            <option *ngFor="let plan of memberPlanList" [value]="plan.code">
              {{ plan.name }}
            </option>
            @if (memberPlanList.length == 1) {
            <option value="">Adesão/Renovação</option>
            }
          </select>
          } @else{
          <span>{{ plan().plan ? plan().plan.planName : "-----" }}</span>
          }
        </div>
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Situação: </b>
          <span>{{
            plan().memberPlanStatus ? plan().memberPlanStatus.name : "-----"
          }}</span>
        </div>
        <div class="row">
          <div
            class="plan-info py-1 col-12 {{
              detailedMode() ? 'col-md-6' : 'col-md-12'
            }}"
            *ngIf="!hasVitoria"
          >
            <b>Data de Ativação: </b>
            <span>{{ plan().dateStart | date : "dd/MM/yyyy" : "+0000" }}</span>
          </div>
          <div
            class="plan-info py-1 col-12 {{
              detailedMode() ? 'col-md-6' : 'col-md-12'
            }}"
          >
            <b>Validade do Plano: </b>
            <span>{{ plan().dateEnd | date : "dd/MM/yyyy" : "+0000" }}</span>
          </div>
        </div>
        @if (this.currentPlan?.totalPlanValue ) {
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Valor do Plano: </b>
          <span>{{ this.currentPlan.totalPlanValue | customCurrency }}</span>
        </div>
        }
        <!-- <div *ngIf="detailedMode()" class="plan-info  py-1 col-12 col-md-12"> <b>Quantidade de Faturas Pagas: </b> <span></span></div> -->
        <!-- <div *ngIf="detailedMode()" class="plan-info  py-1 col-12 col-md-12"> <b>Faturas em Aberto: </b> <span></span></div> -->
        <div *ngIf="detailedMode()" class="plan-info py-1 col-12 col-md-12">
          <b>Renovação Automática: </b>
          <span>{{ plan().automaticRenewal ? "Sim" : "Não" }}</span>
        </div>
        @if (currentPlan?.periodicity) {
        <div class="plan-info py-1 col-12 col-md-12">
          <b>Periodicidade: </b>
          <span>{{ currentPlan?.periodicity | titlecase }}</span>
        </div>
        }
      </div>
      @if( this.memberShipCard != null ){
      <div class="col-4 d-none d-md-block">
        <div class="m-2 d-grid carteirinha justify-content-end">
          <!-- <img class="img-fluid" [src]="carteirinhaImg" alt="Carteitinha">-->
          <div class="memberShipCard" [ngStyle]="styles">
            <div class="row m-0">
              <div class="col-9 d-flex align-items-start flex-column mb-2">
                <div class="mb-auto p-2 d-flex">
                  <img
                    class="img-fluid imgCarteirinha mt-1"
                    [src]="carteirinhaImg"
                    alt="Carteitinha"
                  />
                  <h1 class="align-items-center mt-4" style="font-size: 1rem">
                    N°: {{ memberShipCard?.cardNumber }}
                  </h1>
                </div>
                <div class="p-2">
                  <h1 class="align-items-end" style="font-size: 1.2rem">
                    {{ memberShipCard?.member?.name }}
                  </h1>
                </div>
              </div>
              <div class="col-3 card-lateral"></div>
            </div>
          </div>
          @if(this.userInfo.memberSituation?.id ==
          memberSituationEnum.Adimplente && this.virtualCard == 'true'){
          <div
            id="show-carteirinha"
            class="p-2 text-center show-carteirinha cursor-pointer"
            (click)="showCarteirinha()"
          >
            <label for="" class="pe-1 color-primary"
              >QrCode da Carteirinha</label
            >
            <i class="bi bi-box-arrow-up-right color-primary"></i>
          </div>
          }
        </div>
      </div>
      }
    </div>
    <div class="d-flex justify-content-between mt-auto">
      <app-button
        *ngIf="!detailedMode()"
        class="align-self-end"
        [buttonOptions]="btnPlanDetailsOptions"
        routerLink="/meu-plano"
      ></app-button>

      <app-button
        *ngIf="detailedMode()"
        class="align-self-end"
        [buttonOptions]="btnEditPlanOptions"
        routerLink="/editar-plano"
      ></app-button>

      @if(!isPlanSecondary){ @if(checkMemberSituation('renew')) {
      <app-button
        class="align-self-end"
        [buttonOptions]="btnRenewPlanOptions"
        (buttonClick)="goToRenewPlan(operationType.RENEW)"
      ></app-button>
      } @if(checkMemberSituation('early_renew')) {
      <app-button
        class="align-self-end"
        [buttonOptions]="btnEarlyRenewPlanOptions"
        (buttonClick)="goToRenewPlan(operationType.EARLY_RENEW)"
      ></app-button>
      } @if (plan() && plan().plan && plan().plan.proveDocuments) {
      <div class="" *ngIf="detailedMode()">
        <app-button
          class="align-content-end"
          [buttonOptions]="btnEnvDocumentsOptions"
          [routerLink]="['/documentos']"
        ></app-button>
      </div>
      } }
    </div>
  </div>

  <app-membershipcard-modal
    [memberShipCard]="memberShipCard"
    [modalId]="'membershipCardModal'"
  ></app-membershipcard-modal>

  <!-- <app-view-plan-card [trigger]="trigger$" [memberShipCard]="memberShipCard"></app-view-plan-card> -->
</div>
