import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { MemberPlanDetailModel, Plan } from 'src/app/shared/models/planModel';
import { environment } from 'src/environments/environment';
import { StyleContentPlan } from 'src/app/shared/models/styleContent';
import { Page } from 'src/app/shared/models/Page';

@Injectable({
  providedIn: 'root',
})
export class PlanService extends BaseService {
  private env = environment;
  planSelected: any;

  constructor(http: HttpClient, errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getPlans(): Observable<Plan[]> {
    return this.get<Plan[]>(`${this.env.socioApiUrl}/plans`);
  }

  getPlanById(id: string): Observable<Plan> {
    return this.get<Plan>(`${this.env.socioApiUrl}/plans/${id}`);
  }

  getPublicPlans(): Observable<Page<Plan>> {
    return this.get<Page<Plan>>(
      `${this.env.publicApiSocioUrl}v1/socio/benefits-plan?pageSize=999`
    );
  }

  getPublicBenefitsPlans(): Observable<Page<Plan>> {
    return this.get<Page<Plan>>(
      `${this.env.publicApiSocioUrl}v1/socio/benefits-plan/all-benefits?pageSize=999`
    );
  }

  getPublicPlansCheckbox(): Observable<Plan[]> {
    return this.get<Plan[]>(
      `${this.env.publicApiSocioUrl}v1/socio/benefits-plan/select-check-box`
    );
  }

  getPlanConfig(): Observable<StyleContentPlan> {
    return this.get<StyleContentPlan>(
      `${this.env.publicApiSocioUrl}v1/socio/plan-configs`
    );
  }

  getBenefitsByPlanId(id: string): Observable<Plan> {
    return this.get<Plan>(`${this.env.socioApiUrl}/plans/${id}/benefits`);
  }

  canAddMember(planId: string, memberId: string): Observable<boolean> {
    return this.get<boolean>(
      `${this.env.publicApiSocioUrl}v1/socio/benefits-plan/${planId}/can-add-member/${memberId}`
    );
  }

  public getCurrentPlanDetail(id: string): Observable<MemberPlanDetailModel> {
    return this.http.get<MemberPlanDetailModel>(
      `${this.env.socioApiUrl}/member-plans/details/${id}`
    );
  }

  public getLastExpiredMemberPlan(
    id: string
  ): Observable<MemberPlanDetailModel> {
    return this.http.get<MemberPlanDetailModel>(
      `${this.env.socioApiUrl}/member-plans/last-expired-plan/${id}`
    );
  }

  public getPreviousPlansDetail(
    id: string
  ): Observable<MemberPlanDetailModel[]> {
    return this.http.get<MemberPlanDetailModel[]>(
      `${this.env.socioApiUrl}/member-plans/past-plans/${id}`
    );
  }

  setPlanSelected(plan: any) {
    this.planSelected = plan;
  }

  getPlanSelected() {
    return this.planSelected;
  }
}
